import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth-module/service/auth.service';
import { AuthState } from '@auth-module/store';
import { RouteConstant } from '@constant';
import { ResponsiveService } from '@core/service/responsive.service';
import { GlobalState } from '@core/store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-support-side-button',
  templateUrl: './support-side-button.component.html',
  styleUrls: ['./support-side-button.component.scss']
})
export class SupportSideButtonComponent implements OnInit {
  isMobileView = false;
  sideButtonVisible = false;
  auth = false;
  authCas = false;
  auth2FA = false;

  constructor(
    private store: Store,
    private router: Router,
    private responsiveService: ResponsiveService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isMobileView = this.responsiveService.isMobileView();
    this.responsiveService.mobileViewEvent.subscribe(state => {
      this.isMobileView = state;
      this.sideButtonVisible = false;
    });
    this.store.select(AuthState.isAuthenticated).pipe(
      tap((auth: boolean) => {
        this.auth = auth;
      })
    ).subscribe();
		this.store.select(AuthState.isCasAuthenticated).pipe(
			tap((authCas: boolean) => this.authCas = authCas)
		).subscribe();
    const isValid = this.authService.is2FAValid();
    if (isValid || this.authCas) {
      this.auth2FA = true;
    } else {
      this.auth2FA = false;
    }
  }

  navigate(page: string) {
    if (page === 'DEPOSIT') {
      this.router.navigate([RouteConstant.SUPPORT,RouteConstant.CASH_SOLUTIONS, RouteConstant.MAKING_PAYMENT]);
    } else if (page === 'MOBILE_APP') {
      this.router.navigate([RouteConstant.SUPPORT, RouteConstant.CLIENT_SUPPORT, RouteConstant.CONNECT_WITH_FSM]);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpParamsService } from '../service/http-params.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { IResponse } from '@api-module/model/common/i-response';
import { IAccountBasicInfo } from '@api-module/model/account/i-account-basic-info.model';
import {IAccountOption} from "@api-module/model/account/i-account-option.model";

@Injectable({
  providedIn: 'root'
})
export class AccountRestService {
  readonly BASE_URL_PROTECTED: string = ApiConstant.PROTECTED + ApiConstant.ACCOUNT;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }
   

    getAccountBasicInfo(){
      return this.http.post<IResponse<IAccountBasicInfo>>(this.BASE_URL_PROTECTED + ApiConstant.ACCOUNT_BASIC_INFO,{},{});
    }
    
    getAccountBasicInfoByRefno(paramRefno?:string){
	  const params = this.paramService.buildHttpParams({paramRefno});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.ACCOUNT_BASIC_INFO_BY_REFNO,{},{params});
    }
	
    hasDpmsAccount() {
      return this.http.post<IResponse<Boolean>>(this.BASE_URL_PROTECTED + ApiConstant.HAS_D_ACCOUNT,{},{});
    }

    getAccountListByOrder(uid?:string){
        const params = this.paramService.buildHttpParams({uid});
        return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED+ApiConstant.GET_ACCOUNT_LIST_BY_ORDER, {}, {params});
    }

    checkPlatinumPlusAccount(accountNo: string): any {
      const params = this.paramService.buildHttpParams({accountNo});
      return this.http.get(this.BASE_URL_PROTECTED + ApiConstant.CHECK_IS_PLATINUM_PLUS_ACC, {params});
    }

    findAccountListNonDpms(uid?:string){
      const params = this.paramService.buildHttpParams({uid});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED+ApiConstant.FIND_ACCOUNT_LIST_NON_DPMS, {}, {params});
    }

    getPreferredTransactRefno(uid?:string){
        const params = this.paramService.buildHttpParams({uid});
        return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_PREFERRED_TRANSACT_REFNO,{},{params});
    }

    getPrimaryAccount(uid?:string) {
        const params = this.paramService.buildHttpParams({uid});
        return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_PRIMARY_ACCOUNT,{},{params});
    }

    getTradingRefnoList() {
      return this.http.get<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_TRADING_REFNO_LIST);
    }

    getTradingRefnoDetailList() {
      return this.http.get<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_TRADING_REFNO_DETAIL_LIST);
    }

    findAllDdaBank(virtualBankFlag?: string) {
      const params = this.paramService.buildHttpParams({virtualBankFlag});
      return this.http.get<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.FIND_ALL_BANKS_FOR_DDA, {params});
    }

    saveNewDda(dto) {
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.SAVE_NEW_DDA, dto);
    }
    
    getBankAccountDetails(refno: string,isIgb?: string) {
      const params = this.paramService.buildHttpParams({refno,isIgb});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_BANK_ACCOUNT_DETAILS,{},{params});
    }

    getDetailsFromAccount(accountNo: string) {
      const params = this.paramService.buildHttpParams({accountNo});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_DETAILS_FROM_ACCOUNT,{},{params});
    }

    getAccountProductStatus(paramRefno?: string) {
      const params = this.paramService.buildHttpParams({paramRefno});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_ACCOUNT_PRODUCT_STATUS,{},{params});
    }

    isAccountSuspendTrading() {
      const params = this.paramService.buildHttpParams({});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.IS_ACCOUNT_SUSPEND_TRADING,{},{params});
    }

    getDerivativeConsent(refno: string) {
      const params = this.paramService.buildHttpParams({refno});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_DERIVATIVE_CONSENT,{},{params});
    }

    updateDerivativeConsent(refno: string) {
      const params = this.paramService.buildHttpParams({refno});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.UPDATE_DERIVATIVE_CONSENT,{},{params});
    }

    getW8BenInfoByRefno(refno: string) {
      const params = this.paramService.buildHttpParams({refno});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_W8BEN_INFO_BY_REFNO,{},{params});
    }

		getW8BenInfoListById() {
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_W8BEN_INFO_LIST_BY_ID,{});
		}

    findHkIdrAccountDetailList() {
      const params = this.paramService.buildHttpParams({});
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.FIND_HK_IDR_ACCOUNT_DETAIL_LIST,{},{params});
    }

		isLogged(){
			return this.http.post<IResponse<string>>(this.BASE_URL_PROTECTED + "/is-logged",{});
		}

    getAllRefnoListWithType() {
        const params = this.paramService.buildHttpParams({});
        return this.http.get<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_ALL_REFNO_LIST_WITH_TYPE,{params});
    }

    getAllRefnoListWithTypeAndRpqCount(paramModule: string) {
        const params = this.paramService.buildHttpParams({paramModule});
        return this.http.get<IResponse<Array<IAccountOption>>>(this.BASE_URL_PROTECTED + ApiConstant.GET_ALL_REFNO_LIST_WITH_TYPE_AND_RPQ_COUNT,{params});
    }

    getNoAnswerRefnoListWithType() {
        const params = this.paramService.buildHttpParams({});
        return this.http.get<IResponse<Array<IAccountOption>>>(this.BASE_URL_PROTECTED + ApiConstant.GET_NO_ANSWER_REFNO_LIST_WITH_TYPE,{params});
    }

		isMainAccountAcceptTc(paramId: string){
      const params = this.paramService.buildHttpParams({paramId});
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.IS_MAIN_ACCOUNT_ACCEPT_TC, {},{params});
		}
		
		isAcceptTc(paramRefno: string){
			const params = this.paramService.buildHttpParams({paramRefno});
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.IS_ACCEPT_TC, {}, {params});
		}

    getIamAccountByRefno(refno: string) {
        const params = this.paramService.buildHttpParams({refno});
        return this.http.get<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_IAM_ACCOUNT_BY_REFNO, {params});
    }

    getW8benPdf(refno: string){
      const params = this.paramService.buildHttpParams({refno});
      const headers = new HttpHeaders().set('Accept','application/pdf');
      return this.http.get(ApiConstant.ACCOUNT + ApiConstant.GET_W8BEN_PDF, {params: params, responseType:'blob', headers:headers});
    }

    findFhkDpmsValidAccountById() {
        return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.FIND_FHK_DPMS_VALID_ACCOUNT,{});
    }

    findFhkConfirmedAccountById() {
        return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.FIND_FHK_CONFIRMED_ACCOUNT,{});
    }

		findW8benActiveAccountDetails(){
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.FIND_W8BEN_ACTIVE_ACCOUNT_DETAILS,{});
		}
		
		isSgNotAvailableAccount(paramRefno: string){
			const params = this.paramService.buildHttpParams({paramRefno});
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.IS_SG_NOT_AVAILABLE_ACCOUNT,{},{params});
		}
		
		getSgStockConsent(paramRefno: string){
			const params = this.paramService.buildHttpParams({paramRefno});
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_SG_STOCK_CONSENT,{},{params});
		}

    getCountryCodeList(){
      return this.http.get<IResponse<any>>(ApiConstant.ACCOUNT + ApiConstant.GET_COUNTRY_CODE_LIST, {});
    }

    findBcanActiveAccountList(){
			return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED+ApiConstant.FIND_BCAN_ACTIVE_ACCOUNT, {} );
		}

	  isProfessionalInvestorAccount() {
        return this.http.get<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.IS_PROFESSIONAL_INVESTOR_ACCOUNT,{});
    }

    isEAccount(refno: string) {
        const params = this.paramService.buildHttpParams({refno});
        return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.IS_EACCOUNT,{},{params});
    }

    findMarginEligibleAccountListByOrder() {
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.FIND_MARGIN_ELIGIBLE_ACCOUNT_LIST_BY_ORDER,{});
    }

    getMarginEligilibityDetail() {
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.GET_MARGIN_ELIGIBILITY_DETAIL,{});
    }

    checkMarginCall() {
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.CHECK_CLIENT_FACING_MARGIN_CALL,{});
    }

    hasRegionalMarginAcct() {
      return this.http.post<IResponse<any>>(this.BASE_URL_PROTECTED + ApiConstant.HAS_REGIONAL_MARGIN_ACCOUNT,{});
    }

		hasVaka(paramRefno: string) {
			const params = this.paramService.buildHttpParams({paramRefno});
			return this.http.post<IResponse<string>>(this.BASE_URL_PROTECTED+ApiConstant.HAS_VIRTUAL_ASSET_KNOWLEDGE,{}, {params});
		}
}

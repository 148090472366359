export class BondConstant {
    public static readonly BOND_EXPRESS_IN_BUSINESS_HR_FREQ: number = 15000;
    public static readonly BOND_EXPRESS_OUT_BUSINESS_HR_FREQ: number = 30000;

    public static readonly BUY = 'Buy';
    public static readonly SELL = 'Sell';

    public static readonly BOND_ORDER_PAD_FORM_CONSTANT = {
        ORDER_SIDE: 'selectedOrderSide',
        ACCOUNT: 'selectedAccount',
        SETTLEMENT_ACCOUNT: 'settlementAccount',
        REDEMPTION_ACCOUNT: 'redemptionAccount',
        BOND_EXPRESS: 'buyAtBondExpress',
        PRICE: 'price',
        QUANTITY: 'quantity',
		ORDER_VALIDITY: 'orderValidity',
		SELECTED_GTD_DATE: 'selectedGtdDate'
    };

    public static readonly ORDER_TYPE_FIRM = 'Firm';
    public static readonly ORDER_TYPE_PRICE = 'Price';

    public static readonly BOND_ORDERPAD_INVESTMENT_OBJECTIVE = {
        'Stable Income Seeker': 'fsmone.bonds.bond.suitability.siseeker',
        'High-Yield Seeker': 'fsmone.bonds.bond.suitability.hyseeker',
        'Long-term Depositor': 'fsmone.bonds.bond.suitability.fdalt',
        'Excessive Risk': 'fsmone.bonds.bond.suitability.distress'
    };

    public static readonly BOND_SPECIAL_FEATURE_KEY_MAP = [
        {key: 'callable', translate: 'fsmone.bonds.direct.bond.callable'},
        {key: 'perpetual', translate: 'fsmone.bonds.direct.bond.perpetual'},
        {key: 'puttable', translate: 'fsmone.bonds.direct.bond.puttable'},
        {key: 'makeWholeCall', translate: 'fsmone.bonds.direct.bond.make.whole.call'},
        {key: 'cnySynthetic', translate: 'fsmone.bonds.selector.cnySynthetic'},
        {key: 'lossAbsorption', translate: 'fsmone.bonds.selector.lossAbsorption'}
    ];

    public static readonly BOND_VALIDATE_ERROR_LIST = ['no.rpq.log.id', 'risk.rating.unmatched',
        'investment.horizon.unmatched', 'exceeded.100.of.net.worth', 'exceed.concentration.risk'];

    public static readonly BOND_US_BOND_W8BEN_NOT_UPDATED = 'us.bond.w8ben.not.updated';

	public static readonly INSUFFICIENT_CASH ='fsmone.bonds.insufficient.cash';


    public static readonly PAR_VALUE = 100;

	public static readonly FULL_LOT = 'FULL';
	
	public static readonly ODD_LOT = 'ODD';
	
	public static readonly TIME_AND_SALE: string = 'TIME_AND_SALE';
	
	public static readonly TRADE_SUMMARY: string = 'TRADE_SUMMARY';

	public static readonly BOND_OTC_PRICE: string = 'BOND_OTC_PRICE';
	
	public static readonly BOND_PRICE: string = 'BOND_PRICE';
	
	public static readonly BEX_PRICE: string = 'BEX_PRICE';
	
	public static readonly RANKING: string = 'RANKING';
}

import {Component, Input} from '@angular/core';
import {genRouterLink} from "@util/route";
import {ActivatedRoute, Router} from '@angular/router';
import {AuthState} from "@auth-module/store";
import { Store } from "@ngxs/store";
import {IWatchlistModel} from "@api-module/model/watchlist/i-watchlist.model";
import {IBondWatchlistInfoModel} from "@api-module/model/bond/i-bond-watchlist-info.model";
import { getEnv, bondIpoFactsheet } from '@share/services/product-search.service';
import {ImagePathConstant, RouteConstant} from 'src/app/constant';

@Component({
  selector: 'app-bond-ipo-product-card',
	styleUrls: ['./bond-ipo-product-card.component.scss'],
  templateUrl: './bond-ipo-product-card.component.html',
  providers: []
})
export class BondIpoProductCardComponent {

  isAuthenticated: boolean = this.store.selectSnapshot(AuthState.isAuthenticated);
  isModalVisible: boolean = false;
  addedWatchListIssueCode: string;
  @Input() bondList: any;
  @Input() watchlist: IWatchlistModel[];
  @Input() watchlistLoading: boolean;
  isAddBondModalVisible: boolean = false;
  bondToAddWatchlist: IBondWatchlistInfoModel;
  domainUrl ="https://"+window.location.hostname;
  basePath: string = ImagePathConstant.FUND_HOUSE_IMG_PATH;
  icon: string = 'Add-to-Watchlist.svg';
  bondIpoPath = "/"+RouteConstant.BONDS+"/"+RouteConstant.BOND_IPO+"/";

  readonly watchListLink = genRouterLink(RouteConstant.APP, RouteConstant.WATCHLIST);

  constructor(private router: Router, private store: Store, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    // for (let bond of this.bondList) {
    //   bond.offerYtm = bond.offerYtm.toFixed(3);
    //   bond.bidYtm = bond.offerYtm.toFixed(3);
    //   bond.offerPrice = bond.offerYtm.toFixed(3);
    //   bond.bidPrice = bond.offerYtm.toFixed(3);
    // }
  }

  onClickWatchlistIcon(e: boolean, selectedBond: any): void {
    // this.bondToAddWatchlist = {
    //   issueCode: selectedBond.issueCode,
    //   bondName: selectedBond.bondName,
    //   bidPrice: selectedBond.bidPrice,
    //   bidYtm: selectedBond.bidYtm,
    //   offerPrice: selectedBond.offerPrice,
    //   offerYtm: selectedBond.offerYtm,
    //   bidYldToWorst: selectedBond.bidYldToWorst || null,
    //   offerYldToWorst: selectedBond.offerYldToWorst || null,
    // };
    // if (!this.isAuthenticated) {
    //   const extra = {
    //     queryParams: {
    //       info: JSON.stringify(this.bondToAddWatchlist),
    //       type: 'bond'
    //     }
    //   };
    //   this.router.navigate(
    //     [genRouterLink(RouteConstant.APP, RouteConstant.WATCHLIST)], extra);
    // } else {
    //   this.addedWatchListIssueCode = selectedBond.issueCode;
    //   this.isAddBondModalVisible = true;
    // }
  }

  handleCancel(): void {
    //redirect to login page
    this.isModalVisible = false;
  }

  onCloseAddToWatchlist(): void {
    // this.isAddBondModalVisible = false;
  }

  onNavigate(event, selecedBond: any) {
	bondIpoFactsheet(selecedBond.prelaunchId);
    // if (event.toElement.nodeName === 'IMG') {
    //   this.onClickWatchlistIcon(event, selecedBond);
    // } else {
    //   const routeBondConfig = getRoute(IProductEnum.BOND, selecedBond.issueCode, '', selecedBond.bondName);
    //   openNewTab(routeBondConfig.route, routeBondConfig.params);
    // }

  }

  navigateToRelatedArticle(articleNo){
	var data = genRouterLink('article','view',articleNo);
	let FSM = '/fsm';
	window.open(getEnv()+FSM+data);
  }
}

import { Injectable } from '@angular/core';
import { HttpParamsService } from '../service/http-params.service';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import {IResponse} from "@api-module/model/common/i-response";
import {IProductSearchModel} from "@api-module/model/product/i-product-search.model";

@Injectable({
  providedIn: 'root'
})
export class ProductRestService {
  private readonly BASE_URL: string = ApiConstant.SEARCH;

  constructor(private http: HttpClient, private paramService: HttpParamsService) {

  }

  findProductsByKeyword(product: string, keyword: string, page: any, limit: any) {
    const params = this.paramService.buildHttpParams({
      product,
      keyword,
      page,
      limit
    });

    return this.http.post(this.BASE_URL + ApiConstant.SEARCH_PRODUCTS_BY_TYPE_KEYWORD, {}, { params });
  }

  findProductByKeywordAndExchange(keyword: string, productType: string, exchange: string, page: any, limit: any, isDisplay: boolean) {
    const params = this.paramService.buildHttpParams({keyword,productType,exchange,page,limit,isDisplay});

    return this.http.post(this.BASE_URL + ApiConstant.SEARCH_PRODUCTS_BY_KEYWORD_EXCHANGE, {}, { params });
  }

  findProductsForWatchlist(product: string, keyword: string, page: any, limit: any) {
    const params = this.paramService.buildHttpParams({
      product,
      keyword,
      page,
      limit
    });

    return this.http.post<IResponse<Array<IProductSearchModel>>>(this.BASE_URL + ApiConstant.SEARCH_PRODUCTS_FOR_WATCHLIST, {}, { params });
  }

  getArticlesByKeywords(keyword: String, page: number, limit: number){
	const params = this.paramService.buildHttpParams({keyword,page,limit});
	return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.SEARCH_ARTICLES_BY_KEYWORD, {}, {params});
  }
}

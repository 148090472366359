<app-web-scaffold>
    <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-2"
            *ngIf="showSearchFilter">
        <nz-col nzSpan="14">
            <nz-row nzAlign="middle" nzJustify="left" nzGutter="20" nzType="flex">
                <nz-col nzSpan="12">
                    <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
                        <input type="text" nz-input placeholder="{{'fsmone.search' | translate}}"
                               [(ngModel)]="searchValue" (ngModelChange)="search()"/>
                    </nz-input-group>
                </nz-col>
                <nz-col nzSpan="12">
                    <label class="text-xs"><span translate="fsmone.total.bonds"
                                                 translate-default="Total Bonds:"></span> {{this.bondExpressDisplayList?.length}}
                    </label>
                </nz-col>
            </nz-row>
        </nz-col>
        <nz-col *ngIf="isRMOEnabled">
        	<div class="d-flex gap-two align-items-center">
	            <p [ngClass]="{'font-weight-bold text-black':!isViewSmallLot, 'text-black-5':isViewSmallLot}" class="mb-0">{{'fsmone.full.lot'|translate}}</p>
	            <uic-switch-v1-wrapper id="bond">
	                <nz-switch [(ngModel)]="isViewSmallLot" (ngModelChange)="search()"></nz-switch>
	            </uic-switch-v1-wrapper>
	            <p [ngClass]="{'font-weight-bold text-black':isViewSmallLot, 'text-black-5':!isViewSmallLot}" class="mb-0">{{'fsmone.small.lot'|translate}}</p>
	        </div>
        </nz-col>
        <nz-col [ngClass]="{'text-right' : isMobile$ | async}">
            <label class="text-primary"><a nz-popover nzTrigger="click" nzPopoverPlacement="bottom"
                                           [nzPopoverContent]="columnFilterContent">
                <b class="align-middle text-primary"><img [src]="GENERAL_ICON_PATH + 'table_filter.svg'"
                                                          alt="filter"/> {{'column.visibility'|translate}}</b>
            </a></label>
        </nz-col>
    </nz-row>
</app-web-scaffold>

<app-mobile-scaffold>
    <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-2"
            *ngIf="showSearchFilter">
        <nz-col nzSpan="24">
            <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClose">
                <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue" (ngModelChange)="search()"/>
            </nz-input-group>
        </nz-col>
    </nz-row>
    <nz-row *ngIf="showSearchFilter && isRMOEnabled">
    	<nz-col class="pt-3">
        	<div class="d-flex gap-two align-items-center">
	            <p [ngClass]="{'font-weight-bold text-black':!isViewSmallLot, 'text-black-5':isViewSmallLot}" class="mb-0">{{'fsmone.full.lot.price'|translate}}: </p>
	            <uic-switch-v1-wrapper id="bond">
	                <nz-switch [(ngModel)]="isViewSmallLot" (ngModelChange)="search()"></nz-switch>
	            </uic-switch-v1-wrapper>
	            <p [ngClass]="{'font-weight-bold text-black':isViewSmallLot, 'text-black-5':!isViewSmallLot}" class="mb-0">{{'fsmone.small.lot.price'|translate}}</p>
	    	</div>
        </nz-col>
    </nz-row>
    <nz-row nzAlign="middle" nzJustify="space-between" nzGutter="20" nzType="flex" class="py-3"
            *ngIf="showSearchFilter">
        <nz-col nzSpan="12" class="">
            <label calss="text-xs"><span translate="fsmone.total.bonds"
                                         translate-default="Total Bonds:"></span> {{this.bondExpressDisplayList?.length}}
            </label>
        </nz-col>
        <nz-col nzSpan="12" class="text-right">
            <label class="text-primary"><a nz-popover nzTrigger="click" nzPopoverPlacement="bottom"
                                           [nzPopoverContent]="columnFilterContent">
                <b class="align-middle text-primary"><img [src]="GENERAL_ICON_PATH + 'table_filter.svg'"
                                                          alt="filter"/> {{'column.visibility'|translate}}</b>
            </a></label>
        </nz-col>
    </nz-row>
</app-mobile-scaffold>


<nz-table #bondExpressTable
          [nzData]="bondExpressDisplayList"
          [nzPageSize]="this.bondExpressList?.length"
          nzShowPagination="false"
          [nzTotal]="this.bondExpressList?.length" [nzShowTotal]="rangeTemplate"
          [nzScroll]="{ x: '100%' }">
    <thead (nzSortChange)="sort($event)">
    <tr>
        <th nzLeft="0" nzShowSort nzSortKey="bondName" [ngClass]="{'shadow': (isMobile$ | async)}">
            <div [ngStyle]="(isMobile$ | async) ? {'width': '150px', 'max-width': '180px'} : null">
                <span translate="fsmone.bond.name" translate-default="Bond Name"></span>
            </div>
        </th>
        <ng-container *ngFor="let column of columnDisplayList; let i = index">
            <th [ngStyle]="(column.minWidth) ? {'min-width': column.minWidth} : null" 
            	[hidden]="!column.checked" 
				[nzShowSort]="isSortableColumn(column.value)" 
				[nzSortKey]="column.value">{{column.label}}</th>
        </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of bondExpressTable.data; let i = index">
        <td nzLeft="0" [ngClass]="{'shadow': (isMobile$ | async)}">
            <span class="font-size-md"><img *ngIf="isRMOEnabled" [src]="BOND_EXPRESS_ICON_PATH + 'BondExpressIcon.svg'" alt="BondExpress" class="mr-1"/> <a [href]="getToBondFactsheetUrl(item?.issueCode,item?.bondName)"
                                          target="_blank"
                                          [innerHTML]="item.bondName"></a></span><br>
            <span class=" mr-1">{{item.issuer}}</span> <br>
        </td>
        <td [hidden]="getIsHideColumn('annualCoupon')">
            <div *ngIf="item.annualCoupon">{{item.annualCoupon | number: '1.3-3'}}</div>
            <div *ngIf="!item.annualCoupon">-</div>
        </td>
        <td [hidden]="getIsHideColumn('yearsToMaturity')">
            <div *ngIf="!item.yearsToMaturity">-</div>
            <div *ngIf="item.yearsToMaturity && isNumber(item.yearsToMaturity)">
                <span *ngIf="item.perpetual!='Y'">{{item.yearsToMaturity | number: '1.2-2'}}</span>
                <span *ngIf="item.perpetual=='Y'">{{"fsmone.bonds.direct.bond.perpetual" | translate}}</span>
            </div>
            <div *ngIf="item.yearsToMaturity && !isNumber(item.yearsToMaturity)">{{"fsmone.bonds.factsheet.maturity" | translate}}</div>
        </td>
        <td [hidden]="getIsHideColumn('minAmount')">
            <div *ngIf="item.minAmount" class="text-center">{{item.minAmount | number: '1.0'}}</div>
            <div *ngIf="!item.minAmount" class="text-center">-</div>
        </td>
        <td [hidden]="getIsHideColumn('minAmountPI')">
            <div *ngIf="item.minAmountPI" class="text-center">{{item.minAmountPI | number: '1.0'}}</div>
            <div *ngIf="!item.minAmountPI" class="text-center">-</div>

        </td>

        <td class="text-center" [hidden]="getIsHideColumn('offerYield')">
            <div *ngIf="item.offerYield && item.bondMatured=='N'">
                {{item.offerYield | number: '1.2-2'}}%
            </div>
            <div *ngIf="!item.offerYield || item.bondMatured=='Y' ">
                -
            </div>
        </td>
        <td class="text-right" [hidden]="getIsHideColumn('offerPrice')" style="min-width: 130px;">

            <div *ngIf="item.offerPrice">
                <div class="d-inline-block font-weight-bold">
                    {{item.offerPrice | number: '1.3-3'}}
                </div>
                <div *ngIf="item.askPriceChange && item.askPriceChange>=0" class="text-profit d-inline-block">
                    (<i class="anticon anticon-caret-up"></i>{{item.askPriceChange | number: '1.3-3'}})
                </div>
                <div *ngIf="item.askPriceChange && item.askPriceChange<0" class="text-loss d-inline-block">
                    (<i class="anticon anticon-caret-down"></i>{{item.askPriceChange | number: '1.3-3'}})
                </div>
                <button *ngIf="isOpenMarket || (isRMOEnabled && !isOpenMarket && !isViewSmallLot)" class="ml-1" nz-button nzType="success" nzSize="small"
                        (click)="buyNow(item.issueCode)">{{'buy' | translate}}</button>
<!--                 <button *ngIf="isOpenMarket || (!isOpenMarket && isRMOEnabled && (item.fullLotRMO || item.smallLotRMO))" class="ml-1" nz-button nzType="success" nzSize="small" -->
<!--                         (click)="buyNow(item.issueCode)">{{'buy' | translate}}</button> -->
            </div>
            <div *ngIf="!item.offerPrice">
                -
            </div>

        </td>
        <td class="text-left" [hidden]="getIsHideColumn('bidPrice')" style="min-width: 130px;">
            <div *ngIf="item.bidPrice">
                <button *ngIf="isOpenMarket || (isRMOEnabled && !isOpenMarket && !isViewSmallLot)" class="mr-1" nz-button nzType="theme" nzSize="small"
                        (click)="sellNow(item.issueCode)">{{'sell' | translate}}</button>
                <div class="d-inline-block font-weight-bold">
                    {{item.bidPrice | number: '1.3-3'}}
                </div>
                <div *ngIf="item.bidPriceChange && item.bidPriceChange>=0" class="text-profit d-inline-block">
                    (<i class="anticon anticon-caret-up"></i>{{item.bidPriceChange | number: '1.3-3'}})
                </div>
                <div *ngIf="item.bidPriceChange && item.bidPriceChange<0" class="text-loss d-inline-block">
                    (<i class="anticon anticon-caret-down"></i>{{item.bidPriceChange | number: '1.3-3'}})
                </div>
            </div>

            <div *ngIf="!item.bidPrice">
                -
            </div>
        </td>
        <td class="text-center" [hidden]="getIsHideColumn('articles')">
            <div *ngIf="item.article!='' && item.article!=null">
                <a class="font-weight-bold text-sm" [href]="getRelatedArticleUrl(item?.article,item.bondName)"
                   target="_blank">{{'fsmone.view' | translate}}</a>
            </div>
            <div *ngIf="item.article=='' || item.article==null">
                <p>-</p>
            </div>
        </td>
    </tr>
    </tbody>
</nz-table>

<ng-template #rangeTemplate let-range="range" let-total><span translate="show"
                                                              translate-default="show"></span> {{ range[0] }}
    -{{ range[1] }} , <span translate="fsmone.fsm.smart.total" translate-default="Total"></span> {{ total }} <span
            translate="fsmone.results" translate-default="Results"></span></ng-template>

<ng-template #prefixIconSearch>
    <i nz-icon nzType="search"></i>
</ng-template>

<ng-template #suffixIconClose>
    <i class="input-clear" (click)="clearInput()" nz-icon nzType="close" nzTheme="outline" role="button"></i>
</ng-template>


<ng-template #columnFilterContent>
    <div class="py-3 filter-content overflow-y-auto">
        <div *ngFor="let item of columnDisplayList; let i=index">
            <label class="d-flex mb-2" nz-checkbox [(ngModel)]="item.checked">{{item.label}}</label>
        </div>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';
import { IResponse } from '@api-module/model/common/i-response';
import { ITwoFaModel } from '@api-module/model/authority/i-two-fa.model';
import {AuthConstant} from "src/app/constant";
import {IBondSelectorQueryModel} from '@api-module/model/bond/i-bond-selector-query.model';
import {PageModel} from '@api-module/model/pagination/page.model';
import {BondBuySellPreCheckModel} from '@api-module/model/bond/bond-buy-sell-pre-check.model';
import {BondSelectListSearchModel} from '@api-module/model/bond/bond-select-list-search.model';
import { UploadFile } from "ng-zorro-antd";

@Injectable({
  providedIn: 'root'
})


export class BondService {
  
  readonly BASE_URL: string = ApiConstant.BOND;
  readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.BOND + ApiConstant.TRANSACTION;
  
  constructor(private http: HttpClient, private paramService: HttpParamsService) {}

  findWebcastList(quantity:number) {
    const params = this.paramService.buildHttpParams({quantity});
    return this.http.post(this.BASE_URL + ApiConstant.FIND_WEBCAST_LIST, {}, {params});
  }

  findBondYieldCurveChart(country:string,showDate:string) {
    const params = this.paramService.buildHttpParams({country,showDate});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_YIELD_CURVE_CHART, {}, {params});
  }

  findBondYieldCurveMaturityChart(country:string,maturity:string) {
    const params = this.paramService.buildHttpParams({country,maturity});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_YIELD_CURVE_MATURITY_CHART, {}, {params});
  }

  findBondIndexData() {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_INDEX_DATA, {});
  }

  findBondIndexChart(indexDate:string,marketCode:string,performancePeriod:string) {
	const params = this.paramService.buildHttpParams({indexDate,marketCode,performancePeriod});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_INDEX_CHART, {},{params});
  }

  findBondSectors() {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_SECTOR, {});
  }

  findBondBestWorstList(isGainer:boolean,sector:string,period:string) {
	const params = this.paramService.buildHttpParams({isGainer,sector,period});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_BEST_WORST_LIST, {},{params});
  }

  findBondChart(issueCodeType:any[],graphType:string,period:string,type:string) {
	const params = this.paramService.buildHttpParams({issueCodeType,graphType,period,type});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_LIST_CHART, {},{params});
  }

  getBondMarketList(){
    return this.http.post(this.BASE_URL + ApiConstant.BOND_MARKET_LIST, {});
  }

  findAllActiveBonds() {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_LIST, {});
  }

  calculateBondResult(transactionType:string,issueCode:string,quantity:number,orderType:string,price:number,settlement:string,accountNo:string) {
	const params = this.paramService.buildHttpParams({transactionType,issueCode,quantity,orderType,price,settlement,accountNo});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_CALCULATE, {}, {params});
  }

  findBondPerformance(issueCode:any[],period:string) {
	const params = this.paramService.buildHttpParams({issueCode,period});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_PERFORMANCE_LIST, {}, {params});
  }

  findBondFactsheet(issueCode:string, accountNo?:string) {
	const params = this.paramService.buildHttpParams({issueCode,accountNo});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_FACTSHEET_DATA, {}, {params});
  }

  getBondExpress(issueCode:string) {
	const params = this.paramService.buildHttpParams({issueCode});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_EXPRESS_DATA, {}, {params});
  }

  getBondExpressBusinessHour() {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_EXPRESS_CHECK_BUSINESS_HOURS, {});
  }

  findBondTradeCurrency(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_BOND_TRADE_CURRENCY);
  }

  getBondInfoList(bondSelectListSearchModel: BondSelectListSearchModel): Observable<IResponse<PageModel<any>>> {
      return this.http.post<IResponse<PageModel<any>>>(this.BASE_URL + ApiConstant.SELECT_BOND_INFO_LIST, bondSelectListSearchModel);
  }

  searchSelectBondList(searchTerm): Observable<IResponse<any>> {
      const params = this.paramService.buildHttpParams({searchTerm});
      return this.http.get<IResponse<PageModel<any>>>(this.BASE_URL + ApiConstant.SEARCH_SELECT_BOND_LIST, {params});
  }

  getBuySellBondDisp(issueCode: string, paramRefno: string): Observable<IResponse<any>> {
      const params = this.paramService.buildHttpParams({issueCode, paramRefno});
      return this.http.get<IResponse<PageModel<any>>>(this.BASE_URL + ApiConstant.GET_BUY_SELL_BOND_DISP, {params});
  }

  getBondRiskRating(issueCode: string): Observable<IResponse<any>> {
      const params = this.paramService.buildHttpParams({issueCode});
      return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.GET_BOND_RISK_RATING, {params});
  }

  getBondAvailableTrustAccountPaymentModeDisp(paramRefno: string): Observable<IResponse<any>> {
      const params = this.paramService.buildHttpParams({paramRefno});
      return this.http.get<IResponse<PageModel<any>>>(
          this.PROTECTED_BASE_URL + ApiConstant.GET_BOND_AVAIL_TRUST_ACCOUNT_PAYMENT_MODE_DISP_LIST, {params});
  }

  initBondSelector() {
    return this.http.post(this.BASE_URL + ApiConstant.INIT_BOND_SELECTOR, {});
  }

  searchBondSelectorResult(model:IBondSelectorQueryModel) {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_SELECTOR_RESULT, model);
  }
  
  searchBondSelectorResultImproved(model:IBondSelectorQueryModel) {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_SELECTOR_RESULT+'-improved', model);
  }

  getBondProcessingFees(quantity,transactionType,issueCode,currency,category,refno){
	const params = this.paramService.buildHttpParams({quantity,transactionType,issueCode,currency,category,refno});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_PROCESSING_FEES, {}, {params});
  }

  getFxBuffer(bondCurrency, investCurrency) {
      const params = this.paramService.buildHttpParams({bondCurrency, investCurrency});
      return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.FX_BUFFER, {params});
  }

  getBondProcessingFeesWithBEFlag(quantity: number, transactionType: string, issueCode: string,
                                  currencyCode: string, productCategory: string, refno: string, isBondExpressTrade: boolean) {
    const params = this.paramService.buildHttpParams({quantity, transactionType, issueCode,
          currencyCode, productCategory, refno, isBondExpressTrade});
    return this.http.post(this.BASE_URL + ApiConstant.GET_BOND_PROCESSING_FEES, {}, {params});
  }

  validateBondBuy(paramRefno: string, issueCode: string, paramIsBondExpressTrade: boolean): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({paramRefno, issueCode, paramIsBondExpressTrade});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.VALIDATE_BOND_BUY, {params});
  }

  checkComplexBond(issueCode: string): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({issueCode});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.CHECK_COMPLEX_BOND, {params});
  }

  checkSpecialFeatureByIssueCode(issueCode: string, transType?: string, platform?: string, module?: string): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({issueCode, transType, platform, module});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.CHECK_SPECIAL_FEATURE_BOND_BY_ISSUE_CODE, {params});
  }

  getBondFeatureDisclaimerDisp(issueCode: string, transType: string, module: string,
                               refno: string, isRiskExceed: boolean): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({issueCode, transType, module, refno, isRiskExceed});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.GET_SPECIAL_FEATURE_BOND_DISP, {params});
  }

  getPrelaunchBondFeatureDisclaimerDisp(paramPrelaunchId: number, paramRefno: string): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({paramPrelaunchId, paramRefno});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.GET_PRELAUNCH_BOND_FEATURE_DISP, {params});
  }

  getTreasuryNowBondYieldChartData(){
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.GET_TREASURY_NOW_BOND_YIELD_CURVE_CHART_DATA, {});
  }

  checkNormalBond(issueCode: string): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({issueCode});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.CHECK_IS_NORMAL_BOND, {params});
  }

  checkQuestionaireAnswer(issueCode: string, questionaireForm: any): Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({issueCode});
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.CHECK_BOND_QUESTIONAIRE_ANSWER, questionaireForm, {params});
  }

  getAcknowledgeRiskStatement(issueCode: string): Observable<any> {
    const params = this.paramService.buildHttpParams({issueCode});
    return this.http.get<any>(this.BASE_URL + ApiConstant.GET_ACKNOWLEDGE_RISK_STATEMENT, {params});
  }

  preCheckBondBuySell(preCheckModel: BondBuySellPreCheckModel): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.PRE_CHECK_BOND_BUY_SELL, preCheckModel);
  }

  bondConfirmTrade(paramEncrytedPassword: string, paramE2eeRandom: string, preCheckModel: BondBuySellPreCheckModel):
      Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({paramEncrytedPassword, paramE2eeRandom});
    return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.BOND_CONFIRM_TRADE, preCheckModel, {params});
  }

  bondExpressBuyConfirmTrade(paramEncrytedPassword: string, paramE2eeRandom: string, preCheckModel: BondBuySellPreCheckModel):
      Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({paramEncrytedPassword, paramE2eeRandom});
    return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.BOND_EXPRESS_BUY_CONFIRM_TRADE, preCheckModel, {params});
  }

  bondExpressSellConfirmTrade(paramEncrytedPassword: string, paramE2eeRandom: string, preCheckModel: BondBuySellPreCheckModel):
      Observable<IResponse<any>> {
    const params = this.paramService.buildHttpParams({paramEncrytedPassword, paramE2eeRandom});
    return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.BOND_EXPRESS_SELL_CONFIRM_TRADE, preCheckModel, {params});
  }

  getPrelaunchBondInfo(prelaunchId, accountNo){
	const params = this.paramService.buildHttpParams({prelaunchId,accountNo});
    return this.http.get(this.BASE_URL + ApiConstant.PRELAUNCH_BOND_INFO, {params});
  }

  getPrelaunchBondInfoFeatureDisclaimer(prelaunchId){
	const params = this.paramService.buildHttpParams({prelaunchId});
    return this.http.get(this.BASE_URL + ApiConstant.PRELAUNCH_BOND_INFO_FEATURE_DISCLAIMER, {params});
  }

  getBondFeatureDisclaimer(issueCode, accountNo, transType, module, isRiskExceed){
	const params = this.paramService.buildHttpParams({issueCode, accountNo, transType, module, isRiskExceed});
    return this.http.get(this.BASE_URL + ApiConstant.BOND_FEATURE_DISCLAIMER, {params});
  }

  findBondCreditRatingChoices(){
    return this.http.get(this.BASE_URL + ApiConstant.BOND_CREDIT_RATING_CHOICES, {});
  }

  getLatestAskBidPrice(issueCode){
	const params = this.paramService.buildHttpParams({issueCode});
    return this.http.get(this.BASE_URL + ApiConstant.BOND_LATEST_ASK_BID_PRICE, {params});
	
  }

  findBondFactsheetChartData(issueCode) {
	const params = this.paramService.buildHttpParams({issueCode});
    return this.http.post(this.BASE_URL + ApiConstant.BOND_FACTSHEET_CHART, {}, {params});
  }

  bondChartSelectorQuery(model:any) {
    return this.http.post(this.BASE_URL + ApiConstant.BOND_CHART_SELECTOR_RESULT, model);
  }

	validatePrelaunchBondSubscription(refno: string, prelaunchId: string) {
		const params = this.paramService.buildHttpParams({refno, prelaunchId});
		return this.http.post(this.BASE_URL+ApiConstant.VALIDATE_BOND_IPO_SUBSCRIPTION,{},{params});
	}
	
	getBondTopVolume(period: string, chartPeriod: string, results: number) {
		const params = this.paramService.buildHttpParams({period,chartPeriod,results});
		return this.http.post(this.BASE_URL+ApiConstant.BOND_TOP_VOL, {}, {params});
	}
	
	findNearestBondToTenor(paramTenor: number, paramDayMonthYear: string) {
		const params = this.paramService.buildHttpParams({paramTenor,paramDayMonthYear});
		return this.http.post(this.BASE_URL+ApiConstant.FIND_NEAREST_BOND_TO_TENOR, {}, {params});
	}

  getBondInfo(issueCode: string) {
		const params = this.paramService.buildHttpParams({issueCode});
		return this.http.post(this.BASE_URL+ApiConstant.GET_BOND_INFO, {}, {params});
	}
	
	findHighestYieldBond(data){
		return this.http.post(this.BASE_URL+ApiConstant.BOND_HIGHEST_YIELD, data);
	}
	
	findSovereignBondCountryRegion(){
		return this.http.post(this.BASE_URL+ApiConstant.BOND_SOVEREIGN_BOND_COUNTRY_REGION,{});
	}

    getBondTradeCurrencyCodeList() {
        return this.http.get(this.BASE_URL + ApiConstant.GET_BOND_TRADE_CURRENCY_CODE_LIST, {});
    }

    bondEditTrade(paramEncrytedPassword: string, paramE2eeRandom: string, preCheckModel: BondBuySellPreCheckModel):
        Observable<IResponse<any>> {
        const params = this.paramService.buildHttpParams({paramEncrytedPassword, paramE2eeRandom});
        return this.http.post<IResponse<any>>(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.TRANSACTION + ApiConstant.BOND_EDIT_TRADE, preCheckModel, {params});
    }

    bondVoidTransaction(paramRefno: string, paramContractNo: string, paramTransactionStatus: string, paramProductType: string):
        Observable<IResponse<any>> {
        const params = this.paramService.buildHttpParams({paramRefno, paramContractNo, paramTransactionStatus, paramProductType});
        return this.http.post<IResponse<any>>(ApiConstant.PROTECTED + this.BASE_URL + ApiConstant.TRANSACTION + ApiConstant.BOND_VOID_TRANSACTION, {}, {params});
    }

	findBondCorporateActions(paramRefno: string)
	{
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_BOND_CORPORATE_ACTIONS, {}, {params});
	}
	
	getBondCaFile(paramCaId: string)
	{
		const params = this.paramService.buildHttpParams({paramCaId});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_BOND_CA_FILE_LIST, {}, {params});
	}
	
	downloadBondCaFile(paramCaId: string, filename: string)
	{
		const params = this.paramService.buildHttpParams({paramCaId, filename});
		return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.DOWNLOAD_BOND_CA_FILE, {}, {params});
	}

	findCaFormOptionsByCaId(paramCaId: string)
	{
		const params = this.paramService.buildHttpParams({paramCaId});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.FIND_CA_FORM_OPTIONS_BY_CA_ID, {}, {params});
	}

	getCaInstructionDetails(paramCaId: string, paramIssueCode: string, paramRefno: string, paramMode: string)
	{
		const params = this.paramService.buildHttpParams({paramCaId, paramIssueCode, paramRefno, paramMode});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.GET_CA_INSTRUCTION_DETAILS, {}, {params});
	}
	
	createCorporateActionInstruction(files: UploadFile[], corporateActionDto: any, paramEncrytedPassword: string, paramE2eeRandom: string)
	{
		let formData = new FormData();

        files?.forEach((file: any) => 
		{
            formData.append('files', file);
        });

		formData.append('selectedRefno', corporateActionDto?.selectedRefno);
		formData.append('selectedOption', corporateActionDto?.selectedOption);
		formData.append('issueCode', corporateActionDto?.issueCode);
		formData.append('corporateActionId', corporateActionDto?.corporateActionId);
		formData.append('remarks', corporateActionDto?.remarks);
		formData.append('unitsToInstruct', corporateActionDto?.unitsToInstruct);
		formData.append('paramEncrytedPassword', paramEncrytedPassword);
		formData.append('paramE2eeRandom', paramE2eeRandom);

		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CREATE_CORPORATE_ACTION_INSTRUCTION, formData);
	}

}

import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { AppConstant, RouteConstant } from 'src/app/constant';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BondService } from 'src/app/api/rest/bond.rest.service';
import { IResponse } from "@api-module/model/common/i-response";
import { deepCopy } from "@util/deep-copy";
import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider';
import { UtilService } from '@api-module/service/common/util.service'


@Component({
	selector: 'app-bond-selector-filter',
	templateUrl: './bond-selector-filter.component.html',
	providers: [UtilService]
})
export class BondSelectorFilterComponent implements OnInit, OnChanges, OnDestroy {

	@Input() fromOutside: boolean = false;
	initialising = true;

	filterEnum = IBondFilterEnum;

	nytmRange = [0, 17];
	yearRange = [0, 17];
	couponRange = [0, 17];
	investAmtRange = [0, 1050000];
	creditRange = [0, 9];

	nytmOptions: Options = {
		floor: 0,
		ceil: 17,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			if (value == 17) {
				return '> 16';
			} else {
				return String(value);
			}
		}
	};
	yearOptions: Options = {
		floor: 0,
		ceil: 17,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			if (value == 17) {
				return '> 16';
			} else {
				return String(value);
			}
		}
	};
	couponOptions: Options = {
		floor: 0,
		ceil: 17,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			if (value == 17) {
				return '> 16';
			} else {
				return String(value);
			}
		}
	};
	investAmtOptions: Options = {
		floor: 0,
		ceil: 1050000,
		step: 50000,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			if (value == 1050000) {
				return '> $' + this.decimalPipe.transform(1000000);
			} else if (value == 0) {
				return '$0';
			} else {
				return '$' + this.decimalPipe.transform(value);
			}
		}
	};
	creditOptions: Options = {
		floor: 0,
		ceil: 9,
		showTicks: true,
		showTicksValues: true,
		noSwitching: true,
		translate: (value: number, label: LabelType): string => {
			if (value == 0) {
				return 'N.R';
			} else if (value == 1) {
				return 'C';
			} else if (value == 2) {
				return 'CC';
			} else if (value == 3) {
				return 'CCC';
			} else if (value == 4) {
				return 'B';
			} else if (value == 5) {
				return 'BB';
			} else if (value == 6) {
				return 'BBB';
			} else if (value == 7) {
				return 'A';
			} else if (value == 8) {
				return 'AA';
			} else if (value == 9) {
				return 'AAA';
			}
		}
	};

	bondList: Array<any> = [];
	features = [
		{ value: "callable", label: this.translateService.instant("fsmone.callable"), checked: false },
		{ value: "cnySynthetic", label: this.translateService.instant("fsmone.synthetic.cny"), checked: false },
		{ value: "perpetual", label: this.translateService.instant("fsmone.perpetual"), checked: false },
		{ value: "puttable", label: this.translateService.instant("fsmone.putable"), checked: false },
		{ value: "makeWholeCall", label: this.translateService.instant("fsmone.make.whole.call"), checked: false },
		{ value: "lossAbsorption", label: this.translateService.instant("fsmone.bonds.selector.lossAbsorption"), checked: false }
	];

	selectorFilter = {
		bondIssuer: [],
		bondCurrencyCode: [],
		bondType: [],
		couponFrequency: [],
		bondSuitability: [],
		features: [],
		nytmRange: [],
		yearRange: [],
		couponRange: [],
		investAmtRange: [],
		bondCreditRating: []
	};

	selectorSelected = {
		bondIssuer: [],
		bondCurrencyCode: [],
		bondType: [],
		couponFrequency: [],
		bondSuitability: [],
		features: []
	};

	bondFilterSelectedAll = {
		bondIssuer: false,
		bondCurrencyCode: false,
		bondType: false,
		couponFrequency: false,
		bondSuitability: false,
		features: false
	};

	bondFilterSearch = {
		bondSector: false,
		bondSectorSub: false,
		bondType: false,
		bondCurrencyCode: false,
		couponFrequency: false,
		yieldToMaturity: false,
		yieldToWorst: false,
		couponRate: false,
		bondCreditRating: false,
		yearsToMaturity: false,
		bondIssuer: false,
		bondSuitability: false,
		features: false
	};

	selectorFilterSearch = {
		bondSector: [],
		bondSectorSub: [],
		bondType: [],
		bondCurrencyCode: [],
		couponFrequency: [],
		yieldToMaturity: [],
		yieldToWorst: [],
		couponRate: [],
		bondCreditRating: [],
		yearsToMaturity: [],
		bondIssuer: [],
		bondSuitability: [],
		features: []
	};

	bondSelectorModel:any = {
	}

	gotResult: boolean = false;
	moreFilter: boolean = false;
	isLoading: boolean = false;

	constructor(private cdRef: ChangeDetectorRef, private translateService: TranslateService, private router: Router, private bondService: BondService, private decimalPipe: DecimalPipe,
		private util: UtilService) { }

	ngOnInit(): void {
		this.getLocalStorageSelector();
		this.initBondSelector();
	}

	getLocalStorageSelector() {
		if (localStorage.getItem("bondSelectorInput")) {
			this.selectorFilter = JSON.parse(localStorage.getItem("bondSelectorInput"));
			localStorage.removeItem("bondSelectorInput");
		}

		if (localStorage.getItem("bondSelectorModel")) {
			this.selectorSelected = JSON.parse(localStorage.getItem("bondSelectorModel"));
			localStorage.removeItem("bondSelectorModel");
		}
	}

	initBondSelector() {
		if (this.selectorFilter.nytmRange.length > 0) {
			this.nytmRange = this.selectorFilter.nytmRange;
		}

		if (this.selectorFilter.yearRange.length > 0) {
			this.yearRange = this.selectorFilter.yearRange;
		}

		if (this.selectorFilter.couponRange.length > 0) {
			this.couponRange = this.selectorFilter.couponRange;
		}

		if (this.selectorFilter.investAmtRange.length > 0) {
			this.investAmtRange = this.selectorFilter.investAmtRange;
		}

		if (this.selectorFilter.bondCreditRating.length > 0) {
			this.creditRange = this.selectorFilter.bondCreditRating;
		}

		this.bondService.initBondSelector().subscribe((res: IResponse<any>) => {
			if (AppConstant.RESPONSE_SUCCESS === res.status && res.data != "") {
				if (this.selectorFilter.bondCurrencyCode.length == 0) {
					for (let element of res.data.bondCurrencyModel) {
						this.selectorFilter.bondCurrencyCode.push({ 'code': element.code, 'label': this.translateService.instant(element.code.toLowerCase()), 'check': false });
					}
					this.bondFilterSelectedAll.bondCurrencyCode = false;
					this.updateAll(this.filterEnum.BOND_CURRENCY_CODE);
				}

				if (this.selectorFilter.bondSuitability.length == 0) {
					for (let element of res.data.bondSuitabilityModel) {
						this.selectorFilter.bondSuitability.push({ 'code': element.bondSuitability, 'label': this.translateService.instant('fsmone.bonds.bond.suitability.' + element.bondSuitability.toLowerCase()), 'check': false });
					}
					this.bondFilterSelectedAll.bondSuitability = false;
					this.updateAll(this.filterEnum.BOND_SUITABILITY);
				}

				if (this.selectorFilter.bondType.length == 0) {
					for (let element of res.data.bondTypeModel) {
						this.selectorFilter.bondType.push({ 'code': element.code, 'label': this.translateService.instant('fsmone.bonds.bond.type.' + element.typeName.toLowerCase().replace(/-|\s/g, ".")), 'check': false });
					}
					this.bondFilterSelectedAll.bondType = false;
					this.updateAll(this.filterEnum.BOND_TYPE);
				}

				this.selectorFilter.features = this.features;

				for (const [key, value] of Object.entries(res.data.bondInfoIssuerModel)) {
					this.selectorFilter.bondIssuer.push({ 'code': key, 'label': value, 'check': false });
					this.selectorFilter.bondIssuer.sort((a, b) => a.code > b.code ? 1 : -1);
				}

				for (let element of res.data.couponFrequencyModel) {
					if (element.code != '12') {
						this.selectorFilter.couponFrequency.push({ 'code': element.code, 'label': this.translateService.instant('fsmone.bonds.coupon.frequency.' + element.frequencyName.toLowerCase().replace(/-|\s/g, ".")), 'check': false });
					}
				}


				this.bondFilterSelectedAll.features = false;
				this.bondFilterSelectedAll.bondIssuer = false;
				this.bondFilterSelectedAll.couponFrequency = false;

				this.updateAll(this.filterEnum.SPECIAL_FEATURES);
				this.updateAll(this.filterEnum.BOND_ISSUER);
				this.updateAll(this.filterEnum.COUPON_FREQUENCY);
				
				if(localStorage.getItem("outsideEntries")){ // redirection outside bond selector component incl bond landing page
					let entries:any = JSON.parse(localStorage.getItem("outsideEntries"));
					localStorage.removeItem("outsideEntries");
					let filterEntries = {};
					filterEntries = entries.filter;
					for(const [key,value] of Object.entries(filterEntries)){
						if(this.selectorFilter[key]){
							if(['nytmRange','yearRange','couponRange','investAmtRange','bondCreditRating'].includes(key)){
								this.selectorFilter[key] = value;
								switch(key){
									case 'nytmRange':
										this.nytmRange = this.selectorFilter[key];
										break;
									case 'yearRange':
										this.yearRange = this.selectorFilter[key];
										break;
									case 'couponRange':
										this.couponRange = this.selectorFilter[key];
										break;
									case 'investAmtRange':
										this.investAmtRange = this.selectorFilter[key];
										break;
									case 'bondCreditRating':
										this.creditRange = this.selectorFilter[key];
										break;
									default:
										break;	
								}
							} else {
								for(const item of this.selectorFilter[key]){
									if(item.code == value){
										item.check = true;
									}
								}
							}
							
							this.updateSome(key);
						}
					}
				}

				this.initialising = false;

				if (localStorage.getItem("bondSelectorSearch") && localStorage.getItem("bondSelectorSearch") == "Y") {
					this.loadResult();
				}
			}
		});
	}

	ngOnChanges() {

	}

	toggleFilter() {
		this.moreFilter = !this.moreFilter;
	}

	emitNavigation() {
		if (this.fromOutside) {
			localStorage.setItem("bondSelectorInput", JSON.stringify(this.selectorFilter));
			localStorage.setItem("bondSelectorModel", JSON.stringify(this.selectorSelected));
			this.router.navigate([RouteConstant.BONDS + "/" + RouteConstant.TOOLS + '/' + RouteConstant.BOND_SELECTOR]);
		} else {
			this.moreFilter = !this.moreFilter;
		}
	}

	loadResult() {
		if (this.fromOutside) {
			localStorage.setItem("bondSelectorSearch", "Y");
			this.emitNavigation();
		} else {
			if (localStorage.getItem("bondSelectorSearch") && localStorage.getItem("bondSelectorSearch") == "Y") {
				localStorage.removeItem("bondSelectorSearch");
			}
			this.isLoading = true;

			this.bondSelectorModel.yieldMaturityMinValue = this.nytmRange[0];
			this.bondSelectorModel.yieldMaturityMaxValue = this.nytmRange[1];
			this.bondSelectorModel.yieldWorstMinValue = 0;
			this.bondSelectorModel.yieldWorstMaxValue = 17;
			this.bondSelectorModel.yearsMaturityMinValue = this.yearRange[0];
			this.bondSelectorModel.yearsMaturityMaxValue = this.yearRange[1];
			this.bondSelectorModel.couponRateMinValue = this.couponRange[0];
			this.bondSelectorModel.couponRateMaxValue = this.couponRange[1];
			this.bondSelectorModel.minInvestmentMinValue = this.investAmtRange[0];
			this.bondSelectorModel.minInvestmentMaxValue = this.investAmtRange[1];
			this.bondSelectorModel.specialFeature = this.selectorSelected.features;
			this.bondSelectorModel.bondSuitability = this.selectorSelected.bondSuitability;
			this.bondSelectorModel.creditRatingMinValue = this.creditRange[0];
			this.bondSelectorModel.creditRatingMaxValue = this.creditRange[1];
			this.bondSelectorModel.bondIssuers = this.selectorSelected.bondIssuer;
			this.bondSelectorModel.bondCurrency = this.selectorSelected.bondCurrencyCode;
			this.bondSelectorModel.bondType = this.selectorSelected.bondType;
			this.bondSelectorModel.couponFrequency = this.selectorSelected.couponFrequency;

			this.bondService.searchBondSelectorResultImproved(this.bondSelectorModel).subscribe((res: IResponse<any>) => {
				this.bondList = [];
				if (AppConstant.RESPONSE_SUCCESS === res.status && res.data != "") {
					for (const [key, value] of Object.entries(res.data.resultData)) {
						this.bondList.push(value);
					}
					this.bondList.sort((a, b) => a.bondIssuer > b.bondIssuer ? 1 : -1);
					this.gotResult = true;
					this.isLoading = false;
				}
			});
		}
	}


	clearAll(type) {

		if (this.selectorFilterSearch[type] != null) {
			this.selectorFilter[type] = this.selectorFilter[type].map(obj => this.selectorFilterSearch[type].find(o => o.code == obj.code) || obj);
		}

		for (let option of this.selectorFilter[type]) {

			if (option.check == false) {
				this.bondFilterSelectedAll[type] = false;
				this.updateSome(type);
				return
			}
		}

		//this.bondFilterSelectedAll[type] = false;
		this.updateAll(type);
	}

	updateSome(type) {
		this.selectorSelected[type] = [];
		for (let option of this.selectorFilter[type]) {
			if (option.check) {
				this.selectorSelected[type].push(option.code);
			}
		}
	}

	updateAll(type) {
		this.selectorSelected[type] = [];
		if (this.bondFilterSelectedAll[type]) {
			for (let value of this.selectorFilter[type]) {
				this.selectorSelected[type].push(value.code);
			}

			for (let option of this.selectorFilter[type]) {
				option.check = true;
			}
			return;
		}
		for (let option of this.selectorFilter[type]) {
			option.check = false;
		}
	}

	onChangeValue(changeContext: ChangeContext, changeField) {
		if ('nytm' == changeField) {
			this.selectorFilter.nytmRange = this.nytmRange;
		} else if ('years' == changeField) {
			this.selectorFilter.yearRange = this.yearRange;
		} else if ('coupon' == changeField) {
			this.selectorFilter.couponRange = this.couponRange;
		} else if ('invAmt' == changeField) {
			this.selectorFilter.investAmtRange = this.investAmtRange;
		} else if ('credit' == changeField) {
			this.selectorFilter.bondCreditRating = this.creditRange;
		}

//		if (!this.initialising) {
//			this.loadResult();
//		}
	}

	onChangeFeature(updated) {
		this.selectorSelected.features = [];
		for (let feature of this.features) {
			if (feature.checked) {
				this.selectorSelected.features.push(feature.value);
			}
		}
	}

	onSearch(value, filterSelection) {
		this.selectorFilterSearch[filterSelection] = [];
		if (value) {
			this.bondFilterSearch[filterSelection] = true;
			let tempList = deepCopy(this.selectorFilter[filterSelection]);
			this.selectorFilterSearch[filterSelection] = deepCopy(tempList.filter(
				option => option.label?.toLowerCase().indexOf(value.toLowerCase()) !== -1));
		} else {
			this.bondFilterSearch[filterSelection] = false;
		}
	}

	reset() {
		this.nytmRange = [0, 17];
		this.yearRange = [0, 17];
		this.couponRange = [0, 17];
		this.investAmtRange = [0, 1050000];
		this.creditRange = [0, 9];
		this.bondFilterSelectedAll.bondCurrencyCode = false;
		this.updateAll(this.filterEnum.BOND_CURRENCY_CODE);
		this.bondFilterSelectedAll.bondSuitability = false;
		this.updateAll(this.filterEnum.BOND_SUITABILITY);
		this.bondFilterSelectedAll.bondType = false;
		this.updateAll(this.filterEnum.BOND_TYPE);
		this.bondFilterSelectedAll.features = false;
		this.bondFilterSelectedAll.bondIssuer = false;
		this.bondFilterSelectedAll.couponFrequency = false;
		this.updateAll(this.filterEnum.SPECIAL_FEATURES);
		this.updateAll(this.filterEnum.BOND_ISSUER);
		this.updateAll(this.filterEnum.COUPON_FREQUENCY);

		this.features.forEach((specialFeat)=>
		{
			specialFeat.checked = false;
		})
//		this.loadResult();
	}

	dropdownModal(event) {
		if (!event) {
			if (
				/*this.bondSelectorModel.yieldMaturityMinValue !== this.nytmRange[0] ||
				this.bondSelectorModel.yieldMaturityMaxValue !== this.nytmRange[1] ||
				this.bondSelectorModel.yieldWorstMinValue !== 0 ||
				this.bondSelectorModel.yieldWorstMaxValue !== 17 ||
				this.bondSelectorModel.yearsMaturityMinValue !== this.yearRange[0] ||
				this.bondSelectorModel.yearsMaturityMaxValue !== this.yearRange[1] ||
				this.bondSelectorModel.couponRateMinValue !== this.couponRange[0] ||
				this.bondSelectorModel.couponRateMaxValue !== this.couponRange[1] ||
				this.bondSelectorModel.minInvestmentMinValue !== this.investAmtRange[0] ||
				this.bondSelectorModel.minInvestmentMaxValue !== this.investAmtRange[1] ||*/
				!this.util.compareArray(this.bondSelectorModel.specialFeature , this.selectorSelected.features) ||
				!this.util.compareArray(this.bondSelectorModel.bondSuitability , this.selectorSelected.bondSuitability) ||
				/*this.bondSelectorModel.creditRatingMinValue !== this.creditRange[0] ||
				this.bondSelectorModel.creditRatingMaxValue !== this.creditRange[1] ||*/
				!this.util.compareArray(this.bondSelectorModel.bondIssuers , this.selectorSelected.bondIssuer) ||
				!this.util.compareArray(this.bondSelectorModel.bondCurrency , this.selectorSelected.bondCurrencyCode) ||
				!this.util.compareArray(this.bondSelectorModel.bondType , this.selectorSelected.bondType) ||
				!this.util.compareArray(this.bondSelectorModel.couponFrequency , this.selectorSelected.couponFrequency)
			) {
//				this.loadResult();
			}
		}
	}
	
	ngOnDestroy(){
		if(localStorage.getItem("outsideEntries"))
			localStorage.removeItem("outsideEntries");
			
		if (localStorage.getItem("bondSelectorSearch"))
				localStorage.removeItem("bondSelectorSearch");
				
		if (localStorage.getItem("bondSelectorInput"))
			localStorage.removeItem("bondSelectorInput");
		

		if (localStorage.getItem("bondSelectorModel"))
			localStorage.removeItem("bondSelectorModel");
		
	}
}

export enum IBondFilterEnum {
	BOND_SECTOR = 'bondSector',
	BOND_SECTOR_SUB = 'bondSectorSub',
	BOND_TYPE = 'bondType',
	BOND_CURRENCY_CODE = 'bondCurrencyCode',
	COUPON_FREQUENCY = 'couponFrequency',
	BOND_CREDIT_RATING = 'bondCreditRating',
	NEW_BOND = 'newBond',
	MORE_FILTER = 'moreFilter',
	BOND_SUITABILITY = 'bondSuitability',
	BOND_MARKET_DATA = 'bondMarketData',
	BOND_ISSUER = 'bondIssuer',
	EXCHANGE_LISTED = 'exchangeListed',
	BOND_REGISTRATION = 'bondRegistration',
	GEOGRAPHICAL_SECTORS = 'geographicalSectors',
	YIELD_TO_MATURITY = 'yieldToMaturity',
	YIELD_TO_WORST = 'yieldToWorst',
	COUPON_RATE = 'couponRate',
	YEARS_TO_MATURITY = 'yearsToMaturity',
	SPECIAL_FEATURES = 'features',
}
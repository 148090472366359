import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HoldingTabService {
    holdingTabList = [
        {
            value: 'accountSummary',
            label: 'fsmone.account.summary',
            selected: false
        },
        {
            value: 'investment',
            label: 'fsmone.holdings',
            selected: false},
        {
            value: 'cashAccount',
            label: 'cash.account',
            selected: false
        },
				{
            value: 'autoSweepEnrolment',
            label: 'fsmone.fsmone.auto.sweep',
            selected: false
        },
				{
            value: 'marginSummary',
            label: 'fsmone.margin.summary',
            selected: false
        },
        {
            value: 'myRsp',
            label: 'dpms.rsp',
            selected: false
        },
        {
            value: 'transactionHistory',
            label: 'fsmone.historical.transaction',
            elected: false
        },
        {
            value: 'pendingTransaction',
            label: 'fsmone.processing.pending.transaction',
            selected: false
        },
        {
            value: 'monthlyStatement',
            label: 'fsmone.monthly.statement',
            selected: false
        },
        {
            value: 'dpmsValuationReport',
            label: 'fsmone.valuation.report',
            selected: false
        },
        // {
        //     value: 'plSnapshot',
        //     label: 'profit.loss.snapshot',
        //     selected: false
        // },
    ];

    activeViewHoldingTab$ = new BehaviorSubject<string>('accountSummary');
    activeViewRefno$ = new BehaviorSubject<string>('all');

    setActiveViewHoldingTab(tab: string): void {
        this.activeViewHoldingTab$.next(tab);
    }

    setActiveViewRefno(refno: string): void {
        this.activeViewRefno$.next(refno);
    }
}

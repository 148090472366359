import { Injectable } from '@angular/core';
import { HttpParamsService } from '@api-module/service/http-params.service';
import { HttpClient} from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginRestService {
    readonly BASE_URL: string = ApiConstant.LOGIN;

    constructor(private http: HttpClient, private paramService: HttpParamsService) {}

    getPresession(): Observable<object | Array<string>> {
        return this.http.post(this.BASE_URL + ApiConstant.GET_PRESESSION , {});
    }

    isModuleEnabled(module: string, loginId?: string){
        const params = this.paramService.buildHttpParams({module, loginId})
        return this.http.post(this.BASE_URL + ApiConstant.IS_MODULE_ENABLED, {},{params});
    }

    login(loginId: string, password: string) {
        const params = this.paramService.buildHttpParams({
        loginId,
        password
        });

				const credential = {
					loginId: loginId,
					password: password
				}
        return this.http.post(this.BASE_URL + ApiConstant.VALIDATE_PASSWORD, JSON.stringify(credential));
    }

    logout() {
        return this.http.post(this.BASE_URL + ApiConstant.INVALIDATE_TOKEN, {});
    }

    loginCas(username: string, password: string) {
        const params = this.paramService.buildHttpParams({
            username,
            password
        });
        return this.http.post(ApiConstant.FSMONE_LOGIN, {}, { params });
    }

		checkPassword(paramEncrytedPassword: string, paramE2eeRandom: string){
			const params = this.paramService.buildHttpParams({paramEncrytedPassword,paramE2eeRandom});
			return this.http.post(ApiConstant.PROTECTED+ApiConstant.SECURITY+ApiConstant.CHECK_PASSWORD,{},{params});
		}
}
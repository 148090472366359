import { AppConstant } from './app.constant';

export class AccountOpeningConstant {
	public static readonly REGEX_EMAIL = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
	public static readonly REGEX_BANK_ACC_NO = /^[0-9a-zA-Z](?:[0-9a-zA-Z]|-(?!-))*[0-9a-zA-Z]$/;
	public static readonly REGEX_ALPHANUMERIC_SPACES = /^[a-zA-Z0-9\s]*$/;
	public static readonly REGEX_ALPHA = /^[a-zA-Z\s]*$/;
	public static readonly REGEX_NUMERIC = /^[\$0-9,.\s]*$/;
	public static readonly REGEX_CHINESE_CHAR = /^[\u2E80-\u2FD5\u3400-\u4DBF\u4E00-\u9FCC]*$/;
	public static readonly REGEX_NON_CHINESE_CHAR = /^[^\u2E80-\u2FD5\u3400-\u4DBF\u4E00-\u9FCC]*$/;
	public static readonly REGEX_ENGLISH_ADDRESS = "[^\u2E80-\u2FD5\u3400-\u4DBF\u4E00-\u9FCC]*";
	
	public static readonly CORPORATE_ACCOUNT_OPENING_FORM_URL: string = AppConstant.LIVE_URL + "/download/CorporateAccountForm.pdf";
	public static readonly MALE: string = 'male';
	public static readonly FEMALE: string = 'female';
	public static readonly OTHERS = 'others';
	public static readonly DEFAULT_COUNTRY_NAME: string = 'Malaysia';
	public static readonly DEFAULT_COUNTRY_CODE: string = 'HK';
	public static readonly ACCOUNT_TYPE_PERSONAL: string = 'P';
	public static readonly ACCOUNT_TYPE_SMART: string = 'WP';
	public static readonly ACCOUNT_TYPE_JOINT: string = 'J';
	public static readonly ACCOUNT_TYPE_CORPORATE: string = 'C';
	public static readonly ACCOUNT_TYPE_BENEFICIARY: string = 'B';
	public static readonly ACCOUNT_OPENING_TYPE_DISCRET: string = 'D';
	public static readonly ACCOUNT_ROLE_PRIMARY: string = 'P';
	public static readonly ACCOUNT_ROLE_SECONDARY: string = 'S';
	public static readonly B2C_COCODE: string = 'FMY';
	public static readonly B2C_IFAID: string = 'FMY00001'
	public static readonly PERSONAL: string = 'personal';
	public static readonly BENEFICIARY: string = 'beneficiary';
	public static readonly CORPORATE: string = 'corporate';
	public static readonly ACCOUNT_STATUS_PENDING: string = 'pending';
	public static readonly ACCOUNT_STATUS_PROCESSING: string = 'processing';
	public static readonly ACCOUNT_STATUS_CONFIRMED: string = 'confirmed';
	
	public static readonly INCOME_SAVING: string = 'sourceOfIncomeSaving';
	public static readonly INCOME_INHERIT: string = 'sourceOfIncomeInheritance';
	public static readonly INCOME_OTHER: string = 'sourceOfIncomeOther';
	
	public static readonly SAVING_SALARY: string = 'salary';
	public static readonly SAVING_BUSINESS: string = 'business';
	public static readonly SAVING_MPF: string = 'mpf';
	public static readonly SAVING_INVEST: string = 'invest';
	public static readonly SAVING_GIFT: string = 'gifts';
	public static readonly SAVING_OTHER: string = 'others';
	
	public static readonly ADD_TYPE_LOCAL: string = 'local';
	public static readonly ADD_TYPE_OVERSEAS: string = 'overseas';
	
	public static readonly BANK_LOCAL: string = 'local';
	public static readonly BANK_OVERSEAS: string = 'overseas';
	
	public static readonly OCCUPATION_SELF: string = 'self';
	public static readonly OCCUPATION_STUDENT: string = 'student';
	public static readonly OCCUPATION_UNEMPLOYED: string = 'unemployed';
	public static readonly OCCUPATION_RETIRED: string = 'retired';
	public static readonly OCCUPATION_GOV: string = 'govCommissioner';
	
	public static readonly BUSSNATURE_MONEY: string = 'money';
	public static readonly BUSSNATURE_CASINO: string = 'jewelry';
	public static readonly BUSSNATURE_EXPORT: string = 'importExportTrade';
	public static readonly BUSSNATURE_PUBLIC: string = 'publicAdmin';

	public static readonly TAX_OWNER: string = 'Owner';
	public static readonly TAX_BENE: string = 'Beneficiary';
	public static readonly TAX_ULTI_BEN: string = 'Ultimate Beneficial';


	//Dropdowns

	public static readonly YES_NO_TXT_OPTIONS = [
		{
			label: AppConstant.YES_DISPLAY,
			value: AppConstant.YES_FLAG
		},
		{
			label: AppConstant.NO_DISPLAY,
			value: AppConstant.NO_FLAG
		},
	];

	public static readonly ANNUAL_INCOME_OPTIONS = [
		{ value: 'below250000', label: 'fsmone.less.than.250000' },
		{ value: '250000to499999', label: 'fsmone.250000.to.499999' },
		{ value: '500000to749999', label: 'fsmone.500000.to.749999' },
		{ value: '750000to999999', label: 'fsmone.750000.to.999999' },
		{ value: 'above1000000', label: 'fsmone.1000000.and.above' }
	];

	public static readonly DISTRICT_OPTIONS = [
		{ value: 'Hong Kong', label: 'hong.kong' },
		{ value: 'Kowloon', label: 'kowloon' },
		{ value: 'New Territories', label: 'new.territories' }
	];

	public static readonly SOURCE_OF_INCOME = [
		{ value: AccountOpeningConstant.INCOME_SAVING, label: 'fsmone.savings' },
		{ value: AccountOpeningConstant.INCOME_INHERIT, label: 'fsmone.inheritance' },
		{ value: AccountOpeningConstant.INCOME_OTHER, label: 'fsmone.others' },
	];

	public static readonly ADD_SOURCE_OF_SAVING = [
		{ value: AccountOpeningConstant.SAVING_SALARY, label: 'fsmone.salary.or.bonuses' },
		{ value: AccountOpeningConstant.SAVING_BUSINESS, label: 'fsmone.business.profits' },
		{ value: AccountOpeningConstant.SAVING_MPF, label: 'fsmone.mpf' },
		{ value: AccountOpeningConstant.SAVING_INVEST, label: 'fsmone.investment.earnings' },
		{ value: AccountOpeningConstant.SAVING_GIFT, label: 'fsmone.gifts' },
		{ value: AccountOpeningConstant.SAVING_OTHER, label: 'fsmone.others' }
	];
	
	public static readonly TIN_STATUS_LIST = [
		{ label: 'fsmone.tin', value: 'Y' },
		{ label: 'tin.unavailable', value: 'N' }
	];
	
	public static readonly UNAVAILABLE_TIN_REASON_LIST = [
		{ label: 'fsmone.notinreason.1', value: '1' },
		{ label: 'fsmone.notinreason.2', value: '2' },
		{ label: 'fsmone.notinreason.3', value: '3' }
	];
	
	public static readonly BANK_TYPE_LIST = [
		{ label: 'fsmone.local.bank.account', value: AccountOpeningConstant.BANK_LOCAL },
		{ label: 'fsmone.overseas.bank.account', value: AccountOpeningConstant.BANK_OVERSEAS }
	];
	
	public static readonly BANK_CURRENCY_LIST = [
		{ label: 'hkd', value: 'HKD' },
		{ label: 'usd', value: 'USD' },
		{ label: 'cny', value: 'CNY' },
	];
	
	public static readonly MAILING_LANG_LIST = [
		{ value: 'ch', label: 'fsmone.chinese.traditional' },
		{ value: 'zh', label: 'fsmone.chinese.simplified' },
		{ value: 'en', label: 'fsmone.english' }
	];
}

